// @flow
import { type JSSThemeDefaultBalanceType } from '../JSSThemeDefaultBalance';

export const JSSThemeBalance61500: JSSThemeDefaultBalanceType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { white, text2, text1, background1 },
    },
  } = themeVars;
  return {
    BalanceComponent: {
      titleContent: {
        color: white,
      },
      userMenuWrap: {
        color: text2,
      },
      accountMenuWrap: {
        borderTop: `1px solid ${text2}`,
        borderBottom: `1px solid ${text2}`,
      },
    },
    Bonus: {
      bonusValueMobile: {
        color: white,
      },
    },
    AccountPageMenu: {
      userMenuItem: {
        color: text2,
        '&:hover': {
          color: text1,
        },
        '&:focus': {
          color: text2,
        },
      },
    },
    UsernameCompanentMobile: {
      userNameMobile: {
        fontWeight: 'bold',
      },
    },
    AccountPageMenuDesktop: {
      userMenu: {
        margin: [0, 0, margins.lg, margins.lg],
      },
      userMenuItem: {
        background: white,
        color: background1,
        padding: [margins.md, margins.lg],
        '&.active, &:hover': {},
      },
    },
  };
};
