// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeSearch61500 = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { border1, background1 },
    },
  } = themeVars;

  return {
    SearchBar: {
      resultContainer: {
        background: border1,
      },
      iconContainer: {
        color: background1,
        opacity: .6,
      },
      resultBackground: {
        top: 40,
      },
    },
  };
};
