import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu61500: JSSThemeDefaultSportsMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, background2 },
    },
  } = themeVars;

  return {
    SportsMenuDesktop: {
      sport: {
        background: background2,
        '&:hover, &:focus, &.active': {
          background: '#457B9D',
        },
      },
      region: {
        background: background2,
        '&:hover, &:focus, &.active': {
          background: '#457B9D',
        },
      },
    },
    HorizontalSportsListUISmallIcons: {
      item: {
        color: 'rgb(69,123,157,0.5)',
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
        '&.active': {
          color: '#457B9D',
        },
      },
      sportName: {
        '&.active': {
          color: '#457B9D',
        },
      },
    },
    HorizontalSportsListUIBigIcons: {
      list: {
        background: background2,
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        background: white,
      },
      title: {
        color: white,
      },
    },

    SubMenuListUIMobile: {
      heading: {
        opacity: '.6',
        color: white,
      },
    },
    HorizontalSportsTabs: {
      rootSmallIcons: {
        margin: '0 15px',
      },
    },
    HorizontalSportsListIconSmall: {
      sportIcon: {
        fontSize: 18,
      },
      sportName: {
        fontSize: 13,
      },
    },
  };
};
