// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues61500: JSSThemeDefaultPopularLeaguesType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        '@media (pointer: coarse)': {
          background: '#457B9D',
        },
      },
      link: {
        '&:hover, &:active, &:focus': {
          background: '#457B9D',
          color: white,
          // '@media (pointer: coarse)': {
          //   color: text2,
          // },
        },
      },
    },
  };
};
