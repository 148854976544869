// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile61500: JSSThemeDefaultHeaderMobileType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { whiteBg1 },
      colorTypes: { primary, white },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      headerContainer: {
        background: primary,
        color: white,
      },
      menuContainer: {
        ...whiteBg1,
      },
      logoWrapper: {
        width: 150,
        padding: 0,
      },
      logoImg: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      iconContainer: {
        color: white,
      },
    },
  };
};
