// @flow
import type { JSSThemeDefaultResetsType } from '../JSSThemeDefaultResets';

export const JSSTheme61500Resets: JSSThemeDefaultResetsType = (themeVars) => {
  const {
    colors: {
      colorTypes: { white, neutral4 },
    },
  } = themeVars;
  return {
    button: {
      fontWeight: 'bold',
      color: white,
      background: neutral4,
      '&:hover': {
        background: '#f583c2',
        color: white,
      },
    },
  };
};
