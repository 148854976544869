// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive61500: JSSThemeDefaultLiveType = (themeVars) => {
  const {} = themeVars;
  return {
    LiveMenuEvent: {
      LiveEventRanked: {
        background: '#26446d',
      },
      Teams: {
        ' &:hover, &:active, &:focus': {
          color: '#fff',
          opacity: 0.7,
        },
      },
    },
  };
};
