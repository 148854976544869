// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm61500: JSSThemeDefaultAuthFormType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background4, white, text2, text5 },
    },
    fontSizes,
  } = themeVars;
  return {
    DepositButton: {
      link: {
        color: white,
        '&:hover': {
          opacity: 0.7,
        },
      },
      iconWrap: {},
    },
    AuthFormDesktop: {
      depositWrap: {
        color: white,
      },
    },
    LoginFormDesktop: {
      popUpWrapper: {
        background: background4,
        boxShadow: '0 6px 10px rgba(0, 0, 0, 0.3)',
        '&:before': {
          background: background4,
        },
      },
      forgotText: {
        fontSize: fontSizes.sm,
      },
    },
    PasswordResetWithEmail: {
      contactUsLink: {
        color: text2,
        '&:hover': {
          color: text5,
        },
      },
      passwordResetDebounce: {
        color: text2,
        '@media (pointer: coarse)': {
          color: 'inherit',
        },
      },
    },
  };
};
