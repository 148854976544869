// @flow
import { type JSSThemeDefaultSitemapType } from '../JSSThemeDefaultSitemap';

export const JSSThemeSitemap61500: JSSThemeDefaultSitemapType = (themeVars) => {
  const {
    colors: {
      colorTypes: { text2, black },
    },
  } = themeVars;
  return {
    sitemapTitle: {
      color: text2,
    },
    firstLinkLevel: {
      color: text2,
      '&:hover': {
        color: black,
      },
    },
    secondLinkLevel: {
      color: text2,
      '&:hover': {
        color: black,
      },
    },
    thirdLinkLevel: {
      color: text2,
      '&:hover': {
        color: black,
      },
    },
  };
};
