// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';

export const JSSThemeCasino61500: JSSThemeDefaultCasinoType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: {
        white,
        background1,
        background2,
        background3,
        background4,
        neutral3,
        accent,
        black,
      },
      colorCombinations: { whiteBg2 },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategoriesScroll: {
        borderTop: '1px solid #e6e6e6',
      },
      gameCategories: {
        background: white,
        height: 'auto',
        minHeight: '40px',
        overflowX: 'auto',
        position: 'static',
      },
      menuTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        '@media (pointer: coarse)': {
          fontWeight: 'bold',
          fontSize: '12px',
        },
        '@media screen and (max-width: 1150px)': {
          fontSize: '12px',
        },
      },
      menuItem: {
        padding: '0px 16px',
        color: background1,
        borderRight: `1px solid ${background4}`,
        flexDirection: 'row',
        '@media screen and (max-width: 1355px)': {
          padding: [8, margins.md],
          maxWidth: 98,
        },
        '@media screen and (max-width: 1300px)': {
          fontSize: fontSizes.sm,
        },
        '@media screen and (max-width: 1150px)': {
          maxWidth: 88,
        },
        '&.active, &:hover': {
          ...whiteBg2,
          '&::before': {
            filter: 'invert(100%) brightness(4.7) contrast(100%)',
          },
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            ...whiteBg2,
          },
        },
        '@media (pointer: coarse)': {
          '&:hover, &:active, &:focus': {
            color: white,
          },
        },
        '&::before': {
          width: 20,
          height: 25,
          marginRight: 10,
          display: 'none',
          content: '" "',
          backgroundSize: '14px 14px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          '@media (pointer: coarse)': {
            width: 25,
            marginRight: 0,
            marginBottom: 5,
            backgroundSize: '25px 25px',
          },
        },
        '&#providers': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/providers.svg)',
          },
          '@media screen and (max-width: 1355px)': {
            maxWidth: 130,
          },
        },
        '&#favorites': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/favorites.svg)',
          },
          '@media screen and (max-width: 1355px)': {
            maxWidth: 116,
          },
        },
        '&#all-games': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/all_games.svg)',
          },
        },
        '&#jackpot_games, &#jackpotgames': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/jackpot_games.svg)',
          },
          '@media screen and (max-width: 1355px)': {
            maxWidth: 103,
          },
        },
        '&#table_games, &#tablegames': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/table_games.svg)',
          },
          '@media screen and (max-width: 1355px)': {
            maxWidth: 132,
          },
        },
        '&#poker': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/video_poker.svg)',
          },
        },
        '&#video_slots, &#games ,&#genii': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/video_slots.svg)',
          },
        },
        '&#classic_slots': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/bonus_slots.svg)',
          },
          '@media screen and (max-width: 1355px)': {
            maxWidth: 132,
          },
        },
        '&#video_poker': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/video_poker.svg)',
          },
        },
        '&#other_games': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/all_games.svg)',
          },
        },
        '&#live_casino, &#livecasino': {
          '&::before': {
            backgroundImage:
              'url(https://cdn.slottojam.com/images/live_casino.svg)',
          },
        },
      },
      iconChevron: {
        marginLeft: margins.xs,
        '&.active': {
          transform: 'rotateZ(90deg) translateX(1px) translateY(-2px)',
        },
      },
    },
    SearchBox: {
      inputWrap: {
        flexDirection: 'row',
        marginLeft: 'auto',
      },
      inputSearch: {
        border: `1px solid #e6e6e6`,
        color: background1,
        height: '32px',
        alignSelf: 'center',
        margin: [0, margins.md, 0, margins.lg],
        width: 'auto',
        padding: 0,
        textIndent: margins.xs,
        maxWidth: 240,
        minWidth: 120,
        '&::placeholder': {
          color: background1,
          opacity: .5,
        },
        '@media screen and (min-width: 1355px)': {
          width: 200,
        },
        '@media screen and (max-width: 1300px)': {
          width: 125,
          maxWidth: 125,
          minWidth: 125,
        },
      },
      iconSearch: {
        color: background1,
        position: 'relative',
        right: 0,
        transform: 'translateX(-40px)',
        opacity: '.5',
      },
      searchResult: {
        color: neutral3,
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100vw',
        height: '100vh',
        background: background4,
        overflowY: 'scroll',
      },
      beginTyping: {
        paddingLeft: margins.md,
      },
      searchResultHeading: {
        fontSize: fontSizes.xl,
      },
    },
    CasinoContainer: {
      providersFiltersWrap: {
        padding: [margins.md],
        background: background2,
        top: 44,
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '& input': {
          display: 'none',
        },
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      providersFilters: {
        color: white,
        background: background2,
        '&.active': {
          color: black,
          background: background3,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            color: black,
            background: background3,
          },
        },
      },
      searchWrap: {
        background: white,
        position: 'sticky',
        top: 0,
        height: 42,
      },
    },
    CasinoGameLink: {
      rootFun: {
        background: accent,
        textAlign: 'center',
        marginBottom: 0,
        '&:hover': {
          background: '#d54690',
        },
      },
    },
  };
};
