// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes61500: JSSThemeDefaultOutcomesType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;

  return {
    MobileOutcome: {
      '@global': {
        '@keyframes blinkUp': {
          '0%': { backgroundColor: '#02bd2e' },
          '50%': { backgroundColor: '#F1F1F1' },
          '100%': { backgroundColor: '#02bd2e' },
        },
        '@keyframes blinkDown': {
          '0%': { backgroundColor: '#ff2424' },
          '50%': { backgroundColor: '#F1F1F1' },
          '100%': { backgroundColor: '#ff2424' },
        },
      },
      root: {
        color: '#fff',
        '&.active': {
          background: '#E63946',
          color: '#fff',
        },
      },
      priceValue: {
        color: '#fff',
        '&.active': {
          color: '#fff',
        },
      },
      value1x2: {
        opacity: 0.5,
        '&.active': {
          opacity: 0.8,
        },
      },
      rootTrendUp: {
        '&::before': {
          borderColor: '#555',
        },
      },
      rootTrendDown: {
        '&::after': {
          borderColor: '#555',
        },
      },
    },
    Outcome: {
      value1x2: {
        opacity: 0.8,
      },
      trendUp: {
        color: white,
      },
      root: {
        color: '#fff',
        '&.active': {
          color: white,
          background: '#E63946',
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            color: white,
            background: '#E63946',
          },
        },
        '&.disabled': {
          '&:hover': {
            background: '#E63946',
            color: '#fff',
          },
        },
      },
    },
  };
};
