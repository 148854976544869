// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons61500: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background2, white, background1, background6 },
    },
  } = themeVars;
  return {
    Betbuilder: {
      exampleContainer: {
        background: white,
        marginBottom: 10,
      },
    },
    EventPrimaryMarketOutcomesMobile: {
      date: {
        color: '#616161',
      },
      teamItem: {
        color: '#616161',
      },
      eventLink: {},
      eventLinkIcon: {
        color: '#8B8C97',
      },
    },
    MobileWidgetCouponView: {
      sportLabel: {
        color: background2,
      },
      sportInnerLink: {
        color: background2,
      },
      sportLinkIcon: {
        color: '#8B8C97',
      },
    },
    WidgetSport: {
      event: {
        background: background2,
        margin: [margins.md, 15, margins.lg],
      },
    },
    AllLeagues: {
      league: {
        color: '#565757',
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        background: background1,
        '&:hover': {
          background: background6,
        },
      },
    },
    Market: {
      time: {
        color: white,
      },
      date: {
        color: white,
      },
    },
  };
};
