// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems61500: JSSThemeDefaultBannerItemsType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { background2, white },
    },
  } = themeVars;
  return {
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        margin: 5,
        marginRight: 5,
      },
      contentWrapper: {
        backgroundColor: white,
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: white,
      },
      linkEvent: {
        color: background2,
      },
      descriptionTeams: {
        color: background2,
      },
    },
    BannerContent: {
      bannerContentRoot: {
        justifyContent: 'end',
      },
      textWrapperFill: {
        padding: '',
        '@media (max-width: 480px)': {
          padding: '10px 0',
        },
      },
      descriptionSportsbookCarousel: {
        fontSize: 12,
        fontWeight: '900',
        lineHeight: 1.1,
        display: 'inline-block',
        padding: '10px 0 0',
      },
      descriptionMobile: {
        textShadow:
          '0 -2px 0 #000, 0 -2px 0 #000, 0 2px 0 #000, 0 2px 0 #000, -2px 0 0 #000, 2px 0 0 #000, -2px 0 0 #000, 2px 0 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000',
      },
      headerSportsbookCarousel: {
        fontSize: '18px',
        color: background2,
      },
      headerCasinoCarousel: {
        fontSize: 40,
        fontWeight: '900',
        marginBottom: '20px',
        '@media (max-width: 480px)': {
          textShadow:
            '0 -2px 0 #000, 0 -2px 0 #000, 0 2px 0 #000, 0 2px 0 #000, -2px 0 0 #000, 2px 0 0 #000, -2px 0 0 #000, 2px 0 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000',
        },
      },
      descriptionCasinoCarousel: {
        fontSize: 20,
        fontWeight: '900',
        padding: 0,
        marginBottom: 20,
      },
      buttonsWrapperCasinoCarousel: {
        margin: 0,
        '@media (max-width: 480px)': {
          margin: 0,
        },
      },
      descriptionPromo: {
        fontSize: 16,
        fontWeight: '800',
        color: white,
        padding: '0',
      },
      textWrapperPromo: {
        padding: '0',
      },
      buttonsWrapperPromo: {
        margin: [margins.md, 0, 0, 0],
      },
      bannerContentPromo: {
        padding: [margins.md],
        justifyContent: 'space-between',
      },
      headerPromo: {
        fontWeight: 'bold',
        color: white,
        fontSize: '22px',
        textShadow: 'none',
      },
      headerMobile: {
        fontSize: fontSizes.xxl,
      },
    },
    BannerItemCasinoCarousel: {
      heightFix: {
        height: 400,
      },
      content: {
        backgroundPosition: 'center',
      },
    },
    BannerItemSportsbookCarouselMobile: {
      imgContainer: {
        alignItems: 'flex-start',
        maxHeight: '100%',
        minHeight: '150px',
        justifyContent: 'end',
      },
      header: {
        textShadow: '2px 2px black',
        paddingBottom: '20px',
        maxWidth: '155px',
      },
      description: {
        textAlign: 'left',
        textShadow: '2px 2px black',
        display: 'none',
      },
    },
    SideBanner: {
      containerWithContent: {
        marginBottom: 5,
      },
    },
  };
};
