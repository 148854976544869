// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions61500: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { text2 },
    },
  } = themeVars;
  return {
    SeasonalPromotion: {
      promoHeading: {
        padding: 0,
        position: 'relative',
      },
      buttonRoot: {
        background: 'transparent',
        position: 'absolute',
        top: 70,
      },
      img: {
        objectPosition: 'right',
      },
      root: {
        padding: ['18px', margins.md],
      },
    },
    PromotionsComponent: {
      itemsByTypeTitle: {
        color: '#1D3557',
      },
      promoLink: {
        color: text2,
        '&:hover, &:active': {
          color: text2,
          opacity: 0.7,
          borderBottom: `3px solid ${text2}`,
        },
      },
    },
  };
};
