// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme61500,
  JSSThemeVariables61500,
  getJSSThemePatterns61500,
} from './JSSTheme61500';
import { GeoblockPage61500 } from './GeoblockPage61500';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop61500')).FullDesktop61500,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile61500')).FullMobile61500,
  }),
  JSSTheme: JSSTheme61500,
  JSSThemeVariables: JSSThemeVariables61500,
  JSSThemePatterns: getJSSThemePatterns61500,
  GeoblockPage: GeoblockPage61500,
});
