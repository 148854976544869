// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeBonuses61500 = (themeVars: typeof JSSThemeVariables) => {
  const {
    margins,
    colors: {
      colorTypes: { primary, border1, black, accent },
      colorCombinations: { neutral3White },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        ...neutral3White,
        transition: '.2s ease-in',
        '&:hover': {
          color: black,
          background: accent,
        },
        '&.active': {
          color: black,
          background: accent,
        },
      },
      root: {
        color: primary,
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
      tabsItemMobile: {
        color: primary,
        background: border1,
      },
      bonusContainer: {
        background: 'white',
      },
    },
    Bonuses: {
      root: {
        padding: [margins.md, margins.md, margins.xm],
      },
    },
  };
};
