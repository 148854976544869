// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm61500: JSSThemeDefaultFormType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { text2White },
      colorTypes: { neutral2, background3, neutral3 },
    },
    borderRadius,
    margins,
    fontSizes,
  } = themeVars;
  return {
    Input: {
      betslipInput: {
        height: 30,
        background: '#ffffff',
        borderRadius: 5,
        borderColor: background3,
      },
      root: {
        ...text2White,
        border: `1px solid #dedada`,
        '&::placeholder': {
          color: neutral2,
        },
        borderRadius: borderRadius.lg,
        lineHeight: 'auto',
        height: 'auto',
        padding: [10, 10, 10, 14],
      },
    },
    Field: {
      root: {
        marginBottom: margins.xm * 2,
      },
      fieldLabel: {
        color: neutral3,
        fontSize: fontSizes.lg,
        display: 'block',
        padding: [0, 0, margins.xs],
        fontWeight: 'bold',
      },
      checkboxField: {
        margin: ['30px', 0, 0],
        position: 'relative',
      },
    },
  };
};
