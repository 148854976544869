// @flow
import { type JSSThemeDefaultLanguageSwitcherType } from '../JSSThemeDefaultLanguageSwitcher';

export const JSSThemeLanguageSwitcher61500: JSSThemeDefaultLanguageSwitcherType =
  (themeVars) => {
    const {
      colors: {
        colorCombinations: { text2Neutral1 },
        colorTypes: { text1, white, neutral1 },
      },
    } = themeVars;
    return {
      LanguageSelectComponentMobile: {
        select: {
          color: white,
        },
        languageDescription: {
          color: white,
        },
        languageChevron: {
          color: white,
        },
      },
      LanguageSelectComponent: {
        languageSelect: {
          '&:before': {
            background: white,
          },
        },
        languageSelectWrap: {
          color: white,
        },
        languageItem: {
          ...text2Neutral1,
          '&:first-child': {
            borderRadius: '5px 5px 0 0',
          },
          '&:last-child': {
            borderRadius: '0 0 5px 5px',
          },
          '&:hover': {
            color: text1,
            background: neutral1,
          },
        },
      },
    };
  };
