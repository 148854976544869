// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu61500: JSSThemeDefaultHeaderMenuType = (
  themeVars
) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { background3 },
      colorCombinations: { whiteBg1 },
    },
  } = themeVars;
  const menuItemAfterElement = {
    content: '""',
    position: 'absolute',
    textAlign: 'center',
    bottom: '-27px',
    left: '6%',
    width: '90%',
    borderBottom: `3px solid ${background3}`,
  };
  return {
    HeaderMenuDesktop: {
      menuWrapper: whiteBg1,
      menuItem: {
        padding: [0, margins.xm],
        fontWeight: '600',
        '&:hover, &:focus': {
          color: background3,
          '&:after': menuItemAfterElement,
        },
      },
      menuItemActive: {
        color: background3,
        '&:after': menuItemAfterElement,
      },
      menuItemWrapper: {
        borderRight: `1px solid ${background3}`,
      },
    },
    HeaderMenuMobile: {
      menuItem: {
        fontSize: fontSizes.xl,
        '&.active': {
          color: background3,
        },
      },
    },
  };
};
