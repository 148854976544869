// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates61500: JSSThemeDefaultAffiliatesType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { white },
    },
  } = themeVars;

  return {
    text: {
      margin: margins.md,
    },
    affiliatesHome: {
      topBannerHeader: {
        color: white,
      },
      topBannerDescription: {
        color: white,
      },
    },
    affiliatesProducts: {
      productsHeader: {
        padding: '70px 15px 50px',
      },
    },
    affiliatesCommission: {
      commissionHeader: {
        padding: '70px 15px 50px',
      },
    },
    affiliatesContacts: {
      contactsHeader: {
        padding: '70px 15px 50px',
      },
    },
    affiliatesFAQ: {
      FAQHeader: {
        padding: '70px 15px 50px',
      },
    },
  };
};
