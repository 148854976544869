// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets61500: JSSThemeDefaultMyBetsType = (themeVars) => {
  const {
    colors: {
      colorTypes: { accent },
    },
  } = themeVars;

  return {
    MyBets: {
      myBetsTabsItem: {
        background: '#E7E7E7',

        '&:hover, &.active': {
          background: accent,
        },
      },
    },
  };
};
