// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader61500: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background1, white },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: background1,
      },
      authContentWrap: {
        justifyContent: 'unset',
      },
      extraUserElementsWrapper: {
        color: white,
        margin: [margins.md, 0, margins.xs],
      },
      authWrapper: {
        marginTop: 'auto',
        marginBottom: 15,
      },
    },
    LogoBrand: {
      logoWrapper: {
        width: 250,
        padding: '7px 10px 10px',
      },
      logo: {
        maxWidth: 250,
      },
      logoLink: {
        height: 60,
      },
    },
  };
};
