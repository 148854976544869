// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip61500: JSSThemeDefaultBetslipType = (themeVars) => {
  const {
    colors: {
      colorTypes: { white, accent, background3 },
    },
  } = themeVars;
  return {
    BetslipSelection: {
      common: {
        background: white,
      },
    },
    SelectionState: {
      root: {
        color: accent,
        background: '#E7E7E7',
      },
    },
    SingleSelectionInfo: {
      eventDescription: {
        color: '#616161',
      },
      liveLabel: {
        background: '#4A9432',
      },
      marketDescription: {
        color: '#616161',
      },
      priceDescription: {
        color: '#616161',
      },
    },
    BetslipInput: {
      container: {
        background: accent,
      },
      label: {
        color: '#616161',
      },
      fieldContainer: {
        background: background3,
        borderColor: white,
      },
    },
    BetslipErrors: {
      list: {
        background: 'transparent',
        color: '#D63034',
        paddingLeft: 0,
      },
    },
    Receipt: {
      titleContainer: {
        background: accent,
      },
    },
    SingleSelectionStaticInfo: {
      root: {
        color: '#616161',
      },
    },
    EachWayButton: {
      label: {
        '&:before': {
          border: '1px solid #e7e7e7',
          background: '#e7e7e7',
        },
      },
    },

    BetslipTotals: {
      container: {
        color: '#616161',
      },
    },
    ShareBets: {
      message: {
        '&.shown': {
          color: '#999AA4',
          paddingTop: '7px',
        },
      },
    },
  };
};
