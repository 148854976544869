// @flow
import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection61500: JSSThemeDefaultAccountSectionType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { background2, primary },
    },
  } = themeVars;
  return {
    OPMProvider: {
      heading: {
        fontWeight: 'bold',
      },
    },
    OPMForms: {
      description: {
        '& a': {
          color: primary,
        },
      },
      submitButtonRedirect: {
        marginLeft: '40px',
      },
    },
    OPMPageComponent: {
      heading: {
        fontWeight: 'bold',
        fontSize: fontSizes.xxl,
        color: background2,
        paddingLeft: margins.md,
      },
    },
    PendingWithdrawal: {
      heading: {
        fontWeight: 'bold',
        fontSize: fontSizes.xxl,
        color: background2,
      },
    },
    AccountPageDesktop: {
      accountPageLeftContent: {
        width: 300,
        marginTop: margins.lg,
      },
    },
  };
};
