// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu61500: JSSThemeDefaultDropdownMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { neutral1, text2, text1 },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      item: {
        background: neutral1,
        color: text2,
        '&:first-child': {
          borderRadius: '5px 5px 0 0',
        },
        '&:last-child': {
          borderRadius: '0 0 5px 5px',
        },
        '&:hover,&.active': { color: text1, background: neutral1 },
      },
    },
  };
};
