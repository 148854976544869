// @flow
import {
  type JSSThemeShapeType,
  type ColorTypes,
  getColorCombinations,
  type JSSThemeVariablesShapeType,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { getButtonColors } from '../jss-helpers';
import { JSSThemeHeaderMenu61500 } from './JSSThemeHeaderMenu61500';
import { JSSThemeHeader61500 } from './JSSThemeHeader61500';
import { JSSThemeAuthForm61500 } from './JSSThemeAuthForm61500';
import { JSSThemeOutcomes61500 } from './JSSThemeOutcomes61500';
import { JSSThemeBalance61500 } from './JSSThemeBalance61500';
import { JSSThemeBetslip61500 } from './JSSThemeBetslip61500';
import { JSSThemeForm61500 } from './JSSThemeForm61500';
import { JSSThemeBannerItems61500 } from './JSSThemeBannerItems61500';
import { JSSThemeBonuses61500 } from './JSSThemeBonuses61500';
import { JSSThemePromotions61500 } from './JSSThemePromotions61500';
import { JSSThemeDropdownMenu61500 } from './JSSThemeDropdownMenu61500';
import { JSSThemeLanguageSwitcher61500 } from './JSSThemeLanguageSwitcher61500';
import { JSSThemeHeaderMobile61500 } from './JSSThemeHeaderMobile61500';
import { JSSTheme61500Resets } from './JSSTheme61500Resets';
import { JSSThemeCasino61500 } from './JSSThemeCasino61500';
import { JSSThemeSportsMenu61500 } from './JSSThemeSportsMenu61500';
import { JSSThemeAccountSection61500 } from './JSSThemeAccountSection61500';
import { JSSThemePopularLeagues61500 } from './JSSThemePopularLeagues61500';
import { JSSThemeCoupons61500 } from './JSSThemeCoupons61500';
import { JSSThemeSearch61500 } from './JSSThemeSearch61500';
import { JSSThemeMyBets61500 } from './JSSThemeMyBets61500';
import { applyOnlyDesktop } from '../jss-helpers';
import { JSSThemeAffiliates61500 } from './JSSThemeAffiliates61500';
import { JSSThemeOryx61500 } from './JSSThemeOryx61500';
import { JSSThemeLive61500 } from './JSSThemeLive61500';
import { JSSThemeSitemap61500 } from './JSSThemeSitemap61500';

export const colorTypes61500: ColorTypes = {
  ...colorTypes,
  primary: '#457B9D',
  text1: '#8c8c8c',
  text2: '#565757',
  text3: '#A8DADC',
  text4: '#ce6ac6',
  text5: '#a7a7a7',
  background1: '#457B9D',
  background2: '#1D3557',
  background3: '#F1FAEE',
  background4: '#e6e6e6',
  background5: '#555656',
  background6: '#4d89af', // new color
  shadow: 'rgba(0, 0, 0, 0.3)',
  accent: '#A8DADC',
  success: '#21a22d',
  error: '#ff3434',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: '#fece38',
  inform: '#fece38',
  neutral1: '#f6f6f6',
  neutral2: '#9c9c9c',
  neutral3: '#616161',
  neutral4: '#e369ad',
  border1: '#eee',
  border2: '#c6c6c6',
};

const pick = ({
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  neutral1Neutral2,
  text3Neutral1,
  text2Neutral1,
  whiteAccent,
  whiteError,
  neutral1Bg1,
  text2White,
  neutral1Bg2,
  whitePrimary,
  text2Border1,
  whiteText1,
  neutral3White,
}) => ({
  whiteBg1,
  whiteBg2,
  whiteSuccess,
  neutral1Neutral2,
  text3Neutral1,
  text2Neutral1,
  whiteAccent,
  whiteBg3,
  whiteError,
  neutral1Bg1,
  text2White,
  neutral1Bg2,
  whitePrimary,
  text2Border1,
  whiteText1,
  neutral3White,
});

export const colorCombinations61500 = getColorCombinations(
  colorTypes61500,
  pick
);

export const JSSThemeVariables61500: JSSThemeVariablesShapeType = {
  colors: {
    colorCombinations: colorCombinations61500,
    colorTypes: colorTypes61500,
  },
  fonts: {
    default: {
      name: 'Raleway',
      fontFamily: 'Raleway, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'Raleway/Raleway-Regular',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'Raleway/Raleway-Regular',
          fontWeight: 'normal',
          fontStyle: 'italic',
        },
        {
          path: 'Raleway/Raleway-Bold',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
        {
          path: 'Raleway/Raleway-Bold',
          fontWeight: 'bold',
          fontStyle: 'italic',
        },
        {
          path: 'Raleway/Raleway-Bold',
          fontWeight: '900',
          fontStyle: 'normal',
        },
        {
          path: 'Raleway/Raleway-Bold',
          fontWeight: '900',
          fontStyle: 'italic',
        },
        {
          path: 'Raleway/Raleway-Regular',
          fontWeight: '200',
          fontStyle: 'normal',
        },
      ],
    },
  },
};

export const JSSTheme61500: JSSThemeShapeType = (themeVars) => {
  const {
    borderRadius,
    margins,
    colors: {
      colorTypes: {
        white,
        black,
        background1,
        background2,
        background3,
        accent,
        background6,
        text3,
        primary,
      },
    },
  } = themeVars;
  return {
    header: JSSThemeHeader61500(themeVars),
    headerMenu: JSSThemeHeaderMenu61500(themeVars),
    form: JSSThemeForm61500(themeVars),
    authForm: JSSThemeAuthForm61500(themeVars),
    bonuses: JSSThemeBonuses61500(themeVars),
    banners: JSSThemeBannerItems61500(themeVars),
    headerMobile: JSSThemeHeaderMobile61500(themeVars),
    promotions: JSSThemePromotions61500(themeVars),
    dropdownMenu: JSSThemeDropdownMenu61500(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher61500(themeVars),
    casino: JSSThemeCasino61500(themeVars),
    sportsMenu: JSSThemeSportsMenu61500(themeVars),
    accountSection: JSSThemeAccountSection61500(themeVars),
    popularLeagues: JSSThemePopularLeagues61500(themeVars),
    coupons: JSSThemeCoupons61500(themeVars),
    search: JSSThemeSearch61500(themeVars),
    mybets: JSSThemeMyBets61500(themeVars),
    outcomes: JSSThemeOutcomes61500(themeVars),
    balance: JSSThemeBalance61500(themeVars),
    betslip: JSSThemeBetslip61500(themeVars),
    affiliates: JSSThemeAffiliates61500(themeVars),
    oryx: JSSThemeOryx61500(themeVars),
    live: JSSThemeLive61500(themeVars),
    sitemap: JSSThemeSitemap61500(themeVars),
    BetslipMyBetsPanelTitle: {
      title: {
        color: black,
      },
      heading: {
        background: accent,
        color: white,
      },
    },
    Button: {
      secondary: {
        color: black,
        background: text3,
        fontWeight: 'bold',
        '&:hover': {
          color: black,
          background: background3,
        },
      },
      linkAccent: {
        color: '#999AA4',
        '&:hover': {
          color: '#999AA4',
        },
      },
      small: {
        fontSize: '0 18px !important',
        padding: '0',
        lineHeight: 'normal !important',
        height: '32px !important',
      },
      accent: {
        fontWeight: 'bold',
        color: black,
        background: text3,
        width: 'auto',
        '&:hover': {
          color: black,
          background: '#b6ecee',
        },
        '&:disabled:hover': {
          background: `#457b9da1 !important`,
        },
      },
      primary: {
        color: black,
        background: text3,
        fontWeight: 'bold',
        '&:hover': {
          color: black,
          background: background3,
        },
      },
      default: {
        fontWeight: 'bold',
        color: black,
        background: primary,
        width: '115px',
        '&:hover': {
          background: background6,
          color: black,
        },
      },
      large: {
        padding: [margins.xm, margins.md],
        fontLight: 'bold',
        fontSize: '24px',
        borderRadius: borderRadius.lg,
      },
      linkSecondary: {
        color: white,
        ...applyOnlyDesktop({
          '&:hover': {
            color: white,
          },
        }),
      },
    },
    Heading: {
      common: {
        color: background2,
        padding: [margins.md, margins.xm, margins.md],
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        background: white,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotential: {
        color: '#616161',
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: background1,
      },
      textGeoblock: {
        color: black,
      },
    },
    LiveCalendar: {
      dateEvent: {
        background: '#E8E8E8',
        color: background1,
        '&:hover': {
          background: '#457B9D',
        },
      },
      dateTitle: {
        color: '#616161',
      },
      link: {
        color: background1,
        '&:hover': {
          color: white,
        },
      },
      h2: {
        color: '#1D3557',
        fontSize: 20,
        fontWeight: 'bold',
        background: '#e7e7e7',
      },
    },
    NoBetsMessage: {
      root: {
        color: '#616161',
      },
    },
    resets: JSSTheme61500Resets(themeVars),
    PageNotFound: {
      title: {
        fontSize: 30,
      },
      desciption: { fontSize: 14 },
      buttonWrap: {
        margin: [margins.xm, 0],
      },
    },
  };
};

export const getJSSThemePatterns61500: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { whiteText1 },
      colorTypes: { white, black, primary, background6 },
    },
  } = themeVars;
  return {
    buttons: {
      secondary: getButtonColors(whiteText1),
      accent: {
        fontWeight: 'bold',
        color: white,
        background: primary,
        '&:hover': {
          background: background6,
        },
      },
      primary: {
        color: black,
        background: primary,
        '&:hover': {
          color: black,
          background: background6,
        },
      },
    },
  };
};
